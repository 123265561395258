<template>
  <b-row>
    <b-col :cols="getCols()">
      <next-withdrawals/>
    </b-col>
    <b-col :cols="getCols()">
      <payment-evolution/>
    </b-col>
    <b-col :cols="getCols()">
      <summary-current-month/>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    components: {
      NextWithdrawals: () => import('./NextWithdrawals'),
      PaymentEvolution: () => import('./PaymentsEvolution'),
      SummaryCurrentMonth: () => import('./SummaryCurrentMonth'),
    },
    props: {
      client: {
        type: Object,
        default: () => {}
      },
    },
    methods: {
      getCols () {
        const width = this.$store.getters["layout/getInnerWidth"];

        if (width < 1327 && width>= 1185) {
          return '6';
        } else if (width < 1185) {
          return '12';
        } else {
          return '4';
        }
      },
    },
  }
</script>
